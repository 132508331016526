// Select
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

// Filters
// @media (min-width: 1200px)
.content-ctrls-table.subtable {
  width: 50%;
}

.content-ctrls-table {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: end;
  align-items: flex-end;
  -moz-column-gap: 30px;
  -webkit-column-gap: 30px;
  column-gap: 30px;
  row-gap: 10px;
  padding: 10px 32px;
  margin-bottom: 20px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #004884 #e6effd;
}
*, ::after, ::before {
  box-sizing: border-box;
}
//user agent stylesheet
div {
  display: block;
}

// Input Filter

.content-ctrls-table .dataTables_filter {
  -webkit-box-flex: 2;
  flex: 2;
  min-width: 320px;
}

.dataTables_filter {
  float: initial!important;
  flex: 1;
  transform: translateY(-10px);
}
* {
  scrollbar-width: thin;
  scrollbar-color: #004884 #e6effd;
}
*, ::after, ::before {
  box-sizing: border-box;
}
div {
  display: block;
}

.columna    {
  text-align: center;
}

#circuloverde{
  height:20px;
  width:20px;
  background:green;
  -moz-border-radius:50px;
  -webkit-border-radius:50px;
  border-radius:50px;
}

#circuloamarillo{
  height:20px;
  width:20px;
  background:yellow;
  -moz-border-radius:50px;
  -webkit-border-radius:50px;
  border-radius:50px;
}

#circulorojo{
  height:20px;
  width:20px;
  background:red;
  -moz-border-radius:50px;
  -webkit-border-radius:50px;
  border-radius:50px;
}

.table-pagination-govco .content-footer-table.subtable {
  align-items: center;
  align-content: center;
}

.ngx-pagination a {
  padding: unset !important;
}

ul.ngx-pagination {

  li::after {
    content: none !important;
  }

  li::before {
    content: none !important;
  }

  li {
    border-radius: 5px;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none!important;
    span {
      span {
        color: #36c !important;
      }
    }
  }
  li:hover {
    color: #36c !important;
    cursor: pointer;
    background: #E6EFFD !important;
    border-radius: 5px;
    padding: 0.5em 1em;
  }
  li:first-of-type:hover, li:last-of-type:hover {
    color: #36c !important;
    cursor: pointer;
    background: #E6EFFD !important;
    border-radius: 5px;
    padding: 0.5em 0.62em;
  }
  li.current {
    color: white !important;
    cursor: pointer;
    background: #36c !important;
    font-weight: 600;
    border-radius: 5px;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none!important;

    span {
      span {
        color: white !important;
      }
    }
  }
}
