// small spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid var(--blue-typography);
  border-top-color: white;
  animation: spinner 0.8s linear infinite;
}

//large spinner
@keyframes large-spinner {
  to {
    transform: rotate(360deg);
  }
}
.large-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid var(--blue-typography);
  border-top-color: white;
  animation: spinner 0.8s linear infinite;
}
