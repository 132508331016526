ul.ngx-pagination {
  height: 35px !important;
  margin: 0 !important;
  padding: 0 !important;

  a:hover, button:hover {
    background: transparent !important;
  }

  .pagination-next a:after, .pagination-next.disabled:after {
    content: "" !important;
    margin-left: unset !important;
  }

  .pagination-previous a:before, .pagination-previous.disabled:before {
    content: "" !important;
    display: unset !important;
    margin-right: unset !important;
  }

  li {
    height: 35px !important;
    padding: 0.5em 1em !important;

    &:first-of-type, &:last-of-type {
      height: 35px !important;
      padding: 0.5em 1em !important;
    }

    &:not(.pagination-previous):not(.pagination-next) {
      height: 0 !important;
      padding: 0 !important;
      border-radius: 0;
      min-width: 0;
      margin-left: 0;
      text-align: center;
      text-decoration: none !important;

      &.current {
        height: 35px !important;
        padding: 0.5em 1em !important;
        border-radius: 5px;
        min-width: 1.5em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
        cursor: default !important;
      }

      a {
        height: 35px !important;
        padding: 0.5em 1em !important;
        border-radius: 5px;
        min-width: 1.5em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;

        &:hover {
          color: #36c !important;
          cursor: pointer;
          background: #E6EFFD !important;
          border-radius: 5px;
        }
      }
    }

    &.pagination-previous:not(.disabled), &.pagination-next:not(.disabled) {
      width: initial !important;
      height: initial !important;
      padding: 0 !important;
      border-radius: 5px;
      min-width: 0;
      margin-left: 0;
      text-align: center;
      text-decoration: none !important;

      a {
        height: 35px !important;
        padding: 0.5em 1em !important;
        border-radius: 5px;
        min-width: 1.5em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
      }
    }
  }
}

.content-footer-table.subtable {
  // align-items: center;
  // align-content: end!important;
}
