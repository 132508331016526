small {
  height: 20px !important;
}

.small-untouched-hidden{
  visibility: hidden
}

.small-visibility-visible {
  visibility: visible !important;
}

.small-visibility-hidden {
  visibility: hidden !important;
}

// signIn components
.div-visibility-hidden {
  visibility: hidden;
  color: var(--border-element);
}
.div-mat-error {
  height: 17px;
}
.text-valid {
  color: black;
}
.text-invalid {
  color: #888888;
}
.msg-invalid {
  color: var(--pipe-error);
}
.div-visibility-visible {
  visibility: visible;
  color: var(--pipe-error);
}

.ng-invalid:not(form) {
  color: #888888;
  border-radius: 3px;
}
