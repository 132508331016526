.actions-menu {
  cursor: pointer;

  &:hover {
    color: var(--blue-base-components);
  }
}

.mat-menu-item {
  line-height: 32px !important;
  height: 32px !important;
  transition: all 0.5s ease-out;
}

.mat-menu-item:hover {
  background: var(--blue-base-components) !important;
  color: var(--primary-color-600);
}

.mat-menu-panel {
  min-height: 30px !important;
}
