
.msab-popup-cdn {
  border-radius: 3px;
  background: var(--popupBackground);
  font-weight: bold;
  text-align: center !important;
  padding: 0px 0px 10px 0px !important;
  width: 100vw !important;
  //margin-bottom: 50px;
  font-size: 18px;
  z-index: 2000 !important;

  .ant-notification-notice-close-x {
    visibility: hidden !important;
  }

  .ant-notification-notice-description {
    color: white !important;
  }
}
