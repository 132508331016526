.general-text-14 {
  font-size: 14px;
  color: var(--gray-typography);
}

.general-text-18 {
  font-size: 18px;
  color: var(--gray-typography);
}

.general-title-20 {
  color: var(--blue-typography);
  font-weight: bold;
  font-size: 20px;
}

.general-title-24 {
  color: var(--blue-typography);
  font-weight: bold;
  font-size: 24px;
}

